import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { SimplePageHero } from '../components/common';
import {
  JobListingBody,
} from '../components/careers';
import { Layout } from '../components/Layout';
import { THEME_TERTIARY } from '../utils/brand';

const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = arr1.length; i--;) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};


class JobListingTemplate extends Component {
  state = {
    sectionsInView: [],
  }

  sectionInViewHandler = (sectionIndex, isInView) => {
    const { sectionsInView } = this.state;
    let newSectionsInView = [...sectionsInView];
    const indexExists = newSectionsInView.includes(sectionIndex);
    if (isInView) {
      // Intersection Observer has notified us section has come into view
      // indexExists prevents us from adding duplicates
      if (!indexExists) {
        newSectionsInView = [
          ...sectionsInView,
          sectionIndex,
        ];
      }
    } else {
      // Intersection Observer has notified us section is now out of view
      newSectionsInView = sectionsInView.filter(index => index !== sectionIndex);
    }
    if (!arraysEqual(sectionsInView, newSectionsInView)) {
      // Only if a new section has come into, or an existing section has come
      // out of view, we update the state.
      this.setState({
        sectionsInView: newSectionsInView,
      });
    }
  }

  render() {
    const {
      sectionsInView,
    } = this.state;
    // The 'active' section is the section closest to the top of the page that
    // are still in view (therefore, the smallest index in our array)
    const activeSection = sectionsInView.length > 0 ? sectionsInView.reduce((a, b) => Math.min(a, b)) : 0;
    const {
      data: {
        page: {
          data: pageData,
        },
      },
      location,
    } = this.props;
    const {
      jobTitle,
      jobType,
      sections,
      metaTitle,
      metaDescription,
      openGraphImage,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    return (
      <Layout
        location={location}
        seoData={seoData}
      >
        <SimplePageHero
          headline={jobTitle.text}
          description={jobType}
          theme={THEME_TERTIARY}
        />
        <JobListingBody
          activeSection={activeSection}
          sectionInViewHandler={this.sectionInViewHandler}
          sections={sections}
          theme={THEME_TERTIARY}
        />
      </Layout>
    );
  }
}

export default JobListingTemplate;

export const pageQuery = graphql`
  query JobListingBySlug($uid: String!) {
    page: prismicJobListing(uid: { eq: $uid }) {
      data {
        jobTitle: job_title {
          text
        }
        jobType: job_type
        sections {
          content {
            html
          }
          sectionHeading: section_heading {
            text
          }
        }
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
      }
    }
  }
`;
